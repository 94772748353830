import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    // Select, 
    Empty,
    //  Space
} from "antd";
import {
    // DeleteOutlined,
    ArrowRightOutlined,
    //  GiftOutlined, ExclamationCircleOutlined
} from "@ant-design/icons";
import Loading from "../../common/Loading/Loading";
// import moment from "moment";
// import { NewMessageError } from "../../common/MessageError/NewMessageError";
class Confirmation extends Component {
    state = {
        disableNextButton: false
    }

    totalPrice = () => {
        const { services } = this.props

        let total = 0
        for (let service of services) {
            total += service.price
        }
        return total
    }

    getStaffName = (staffId) => {
        const { staffs } = this.props
        return staffs.find(staff => staff._id === staffId)?.name
    }

    render() {
        let { services, loading, currency } = this.props;

        if (loading) {
            return <Loading />;
        }
        const { disableNextButton } = this.state
        return (
            <div style={{ width: "100%" }}>
                <Row
                    justify="center"
                    style={{
                        paddingTop: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                        Confirm your appointment
                    </Col>
                </Row>
                <Row
                    justify="center"
                    style={{
                        paddingBottom: "20px",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <Col
                        xl={10}
                        lg={18}
                        md={18}
                        sm={23}
                        xs={23}
                        style={{ width: "100%" }}
                    >
                        {services.length > 0 ? (
                            <Row>
                                <Col style={{ width: "100%" }}>
                                    {services.map((service, index) => {

                                        return (
                                            <Row
                                                key={service._id + index}
                                                className={
                                                    service.staff
                                                        ? "box box-down"
                                                        : "box box-down red"
                                                }
                                                style={{ padding: "10px", border: "1px solid #cbd5e1", marginBottom: "5px" }}
                                            >
                                                <Col span={20}>
                                                    <h3
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {index + 1}. {service.name}
                                                    </h3>
                                                    <p>
                                                        <span>
                                                            {service.priceType}
                                                        </span>{" "}
                                                        {currency}{service.price}
                                                    </p>
                                                </Col>
                                                {/* <Col span={4}>
                                                    <Button
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        type="link"
                                                        danger
                                                        onClick={() =>
                                                            this.props.handleRemoveService(
                                                                service._id
                                                            )
                                                        }
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col> */}
                                                <Col span={24}>
                                                    <span>{this.getStaffName(service.staff)}</span>
                                                    <span style={{ color: "gray" }}> at {service.start}</span>
                                                    {/* <Select
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className={
                                                            "checking-in-select-staff-for-service"
                                                        }
                                                        size="large"
                                                        placeholder="Select Staff"
                                                        value={service.staff}
                                                        notFoundContent={"There is no staff available for this service. Please talk to the front desk. Thank you!"}
                                                        onChange={(
                                                            value,
                                                            e
                                                        ) => {
                                                            this.props.handleStaffChangeDropdown(
                                                                value,
                                                                e.children,
                                                                index
                                                            );
                                                            //this.forceUpdate()
                                                        }}
                                                    >
                                                        {this.props.getAvailableStaffForService(
                                                            service
                                                        )}
                                                    </Select> */}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                        ) : (
                            <Empty description="No service seleted" />
                        )}
                    </Col>
                </Row>
                {/* {
                    isValidForPromotion &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < Space direction="vertical" size="small" style={{ width: "100%", background: "#00c2cb", color: "white" }} className="box">
                                <div style={{ fontWeight: "bold", fontSize: "16px" }}>{promotion.name}</div>
                                <div>
                                    <span >Discount: </span>
                                    <span>
                                        {promotion.discountType === "amount" && "$"}
                                        {promotion.amount}
                                        {promotion.discountType === "percentage" && "%"}
                                    </span>
                                </div>
                                <div>
                                    Discount type: {promotion.promoType === "onCart" ? "On cart" : "On items"}
                                </div>
                                {promotion.promoType === "onItems" && <div>
                                    Discount items:
                                    <ul>
                                        {this.getPromoItemName().map(item =>
                                            <li key={item}>
                                                {item}
                                            </li>
                                        )}
                                    </ul>
                                </div>}
                            </Space>
                        </Col>
                    </Row>
                } */}
                {/* {
                    promotion && this.totalPrice() < promotion.minimum &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < div style={{ width: "100%", background: "#f7b31c", padding: "10px 20px" }} >
                                <ExclamationCircleOutlined /> You need at least ${promotion.minimum} to apply promotion discount
                            </div>
                        </Col>
                    </Row>
                } */}
                {/* {
                    promotion.promoType === "onItems" && this.getPromoItemName().length === 0 &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < div style={{ width: "100%", background: "#f7b31c", padding: "10px 20px" }} >
                                <ExclamationCircleOutlined /> You need at least 1 item with icon {<GiftOutlined />} to apply promotion discount
                            </div>
                        </Col>
                    </Row>
                } */}
                <Row justify="center">
                    <small style={{ color: "grey", marginTop: "20px", padding: "10px" }}>
                        This site is protected by reCAPTCHA and the Google{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://policies.google.com/privacy"
                        >
                            Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://policies.google.com/terms"
                        >
                            Terms of Service
                        </a>{" "}
                        apply.
                    </small>
                </Row>
                <Row
                    justify="center"
                    className="select-services-sticky-container"
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23} style={{ padding: "10px", backgroundColor: "#F0F2F5", borderRadius: "8px" }}>
                        <Button
                            block
                            type="primary"
                            size="large"
                            shape="round"
                            style={{ height: "50px", zIndex: 9 }}
                            onClick={() => {
                                this.props.handleSubmit();
                            }}
                            disabled={services.length === 0 || disableNextButton}
                        >
                            Confirm <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
    staffs: state.checkingIn.staffs,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
    loading: state.checkingIn.loadingPendingServices,
    promotion: state.promotion.activePromotion,

});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
