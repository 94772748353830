import { ArrowLeftOutlined, SmileTwoTone } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetCheckingInData } from '../../../redux/actions/checking-in/checking-in'
import ClientLoyaltyPoints from '../Thankyou/ClientLoyaltyPoints'

const ConfirmationNoServices = (props) => {

    const [secondCounter, setSecondCounter] = useState(10)
    const dispatch = useDispatch()
    const { business, clientLoyalty } = useSelector(state => state.checkingIn)

    // const timer = () => {
    // 	if (secondCounter > 0) {
    // 		setSecondCounter(secondCounter - 1)
    // 	} else {
    // 		goToHomePage()
    // 	}
    // }
    const goToHomePage = () => {
        dispatch(resetCheckingInData())
        props.handleChangeCountry(business.country)
        props.history.push(`/${props.location}/checking-in/`);
    };

    useEffect(() => {
        // const id = setInterval(timer, 1000);
        // return () => {
        // 	clearInterval(id);
        // };

        if (secondCounter > 0) {
            const timer = setInterval(() => {
                setSecondCounter(prevSeconds => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else {
            goToHomePage()
        }
    })

    return (
        <div style={{ width: "100%" }}>
            <Row
                justify="center"
                style={{
                    padding: "20px",
                    width: "100%",
                    position: "relative",
                    marginTop: "10%",
                }}
            >
                <Col md={16} lg={14} xl={12} sm={24} style={{ width: "100%" }}>
                    <div className="box box-down green animate__animated animate__fadeInUp">
                        {business && (
                            <h2 style={{ fontWeight: "bold" }}>
                                {business.name}
                            </h2>
                        )}

                        <h2 style={{ fontSize: "35px", textAlign: "center" }} className="animate__animated animate__zoomIn animate__delay-1s">

                            <SmileTwoTone twoToneColor="#52c41a" />  Thank you for checking-in
                        </h2>
                        <h2>
                            Please consult our front desk for assistance with your service.
                        </h2>
                        <Button type="primary" style={{ marginTop: "30px" }} size="large" shape="round" onClick={() => goToHomePage()}>
                            <ArrowLeftOutlined /> Back To Home Page (in {secondCounter}s)
                        </Button>
                    </div>
                </Col>

            </Row>
            {clientLoyalty &&
                <ClientLoyaltyPoints />
            }
        </div>

    )
}

export default ConfirmationNoServices