import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input, Modal } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import ServicesAddOns from "./ServicesAddOns";


export class Services extends Component {
    state = {
        search: "",
    };


    handleGoNext = () => {
        let { services } = this.props;
        let staffIds = services[0].staffs
        services.forEach(service => {
            staffIds = staffIds.filter(staff => service.staffs.includes(staff))
        })
        const location = this.props.location;

        if (staffIds.length > 0) {
            this.props.history.push(`/${location}/checking-in/select/staff`);
        }

        else if (this.props.isClientDetailMissing()) {
            this.props.history.push(`/${location}/checking-in/select/client-form`);
        }
        else {
            this.props.history.push(`/${location}/checking-in/select/confirmation`);
        }
    }

    searchOnChange = (search) => {
        this.setState({ search })
    };

    servicesWithSearch = (menu, search) => {
        let servicesWithSearch = menu.map((category) => {
            const newServices = [...category.services]
            const newCategory = { ...category, newServices }
            return newCategory
        });

        if (search !== "") {
            servicesWithSearch = servicesWithSearch.filter((category) => {
                category.services = category.services.filter(service => {
                    return service.name.toLowerCase().includes(search.toLowerCase())
                })
                return !!category
            })
        }

        return servicesWithSearch;
    };

    isServiceSelected = (service) => {
        const { services } = this.props;
        if (service.type === "Combo") {
            return services.some(serv => service.subServices.includes(serv._id))
        }
        else {
            return services.some(serv => serv._id === service._id)
        }
    }

    render() {
        const { menu, services, currency } = this.props;
        const { search } = this.state;

        const servicesWithSearch = this.servicesWithSearch(menu, search);

        return (<div style={{ width: "100%" }}>
            <Row justify="center" style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                    Select your services
                </Col>
            </Row>
            <Row justify="center" style={{ padding: "20px 0 20px 0" }} >

                <Col xl={10} lg={18} md={18} sm={23} xs={23} className="service-container" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                    <Input
                        placeholder="Find services"
                        allowClear
                        onChange={(e) => this.searchOnChange(e.target.value)}
                        size="large"
                        suffix={<SearchOutlined style={{ color: "lightgrey" }} />}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    {servicesWithSearch !== null &&
                        servicesWithSearch.map((category) => {
                            return (
                                category.services.length > 0 && (
                                    <div
                                        key={category._id}
                                        style={{ margin: "0 0 15px" }}
                                    >
                                        <h2
                                            style={{
                                                margin: "5px 0",
                                                // borderLeft: `3px solid ${category.color}`,
                                                borderRadius: "8px",
                                                backgroundColor: category.color ? `${category.color}CC` : "rgba(165, 243, 252, 0.8)",
                                                padding: "5px 8px",

                                                // paddingLeft: "10px",
                                            }}
                                        >
                                            {category.name}
                                        </h2>
                                        <Row>
                                            {category.services
                                                .filter(service => !service.isSubService)
                                                .map(
                                                    (service) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    service._id
                                                                }
                                                                onClick={() => this.props.handleSelectService(service)}
                                                                className={
                                                                    "modal-service " +
                                                                    (this.isServiceSelected(service) &&
                                                                        "modal-service-selected")
                                                                }
                                                                style={{ border: "1px solid #cbd5e1", }}
                                                            >
                                                                <h4
                                                                    style={{
                                                                        margin: "0",
                                                                    }}
                                                                >
                                                                    <strong style={{ paddingRight: "10px" }}>
                                                                        {service.name}
                                                                    </strong>
                                                                    <div
                                                                        style={{
                                                                            float: window.innerWidth > 480 && "right",

                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#8c8c8c",

                                                                            }}
                                                                        >
                                                                            {
                                                                                service.priceType === "From" ? "From" : ""
                                                                            }
                                                                            {" "}
                                                                            {currency}

                                                                            {
                                                                                service.price
                                                                            }

                                                                        </span>



                                                                    </div>
                                                                </h4>
                                                                {service.description >
                                                                    "" && (
                                                                        <span
                                                                            style={{
                                                                                color: "grey",
                                                                            }}
                                                                        >
                                                                            {
                                                                                service.description
                                                                            }
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </Row>
                                    </div>
                                )
                            );
                        })}

                </Col>
            </Row>
            <Row justify="center" className="select-services-sticky-container">
                <Col xl={10} lg={18} md={18} sm={23} xs={23} style={{ padding: "10px", backgroundColor: "#F0F2F5", borderRadius: "8px" }}>
                    <Button
                        block
                        type="primary"
                        size="large"
                        shape="round"
                        style={{ height: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", zIndex: 19 }}
                        onClick={() =>
                            this.handleGoNext()
                        }
                        disabled={
                            services.length === 0
                        }

                    >
                        <span>
                            {services.length > 0
                                ? `${services.length} ${services.length === 1 ? "service" : "services"}`
                                : ""}
                        </span>
                        <span> Next <ArrowRightOutlined /></span>

                    </Button>
                </Col>
            </Row>
            <Modal
                title="Add-Ons Services (Optional)"
                open={this.props.isModalAddOnsOpen}
                onCancel={() => this.props.handleOpenAddOnsModal(false)}
                footer={null}
                style={{ padding: "0px", top: "0" }}
            >
                <ServicesAddOns
                    currentService={this.props.currentService}
                    services={services}
                    currency={currency}
                    handleSelectService={this.props.handleSelectService}
                    handleOpenAddOnsModal={this.props.handleOpenAddOnsModal}
                    addAddOnsToServices={this.props.addAddOnsToServices}
                />
            </Modal>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
