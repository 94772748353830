import { Button } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ServicesAddOns extends Component {
    state = {
        addOns: []
    }

    handleChooseAddOns = (service) => {
        const { addOns } = this.state
        if (addOns.includes(service)) {
            this.setState({ addOns: addOns.filter(serv => serv._id !== service._id) })
        } else {
            this.setState({ addOns: [...addOns, service] })
        }
    }

    getAddOnsObj = () => {
        const { currentService, services, menu } = this.props
        const addOnsIds = currentService?.addOns || [];
        const filteredAddOnsIds = addOnsIds.filter((addOnId) => !services.some((service) => service._id === addOnId));
        const addOnsObj = filteredAddOnsIds
            .map((addOnId) => {
                for (const category of menu) {
                    const foundService = category.services.find((service) => service._id === addOnId);
                    if (foundService) {
                        return foundService;
                    }
                }
                return null;
            })
            .filter((addOn) => addOn !== null);
        return addOnsObj;
    };

    handleAddAddOnsToServices = () => {
        this.props.addAddOnsToServices(this.state.addOns)
        this.setState({ addOns: [] })
        this.props.handleOpenAddOnsModal(false)
    }

    render() {
        const { currency } = this.props
        return (
            <div style={{
                maxHeight: "500px",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none",
            }}>
                {this.getAddOnsObj().map(
                    (service) => {
                        return (
                            <div
                                key={
                                    service._id
                                }
                                onClick={() => this.handleChooseAddOns(service)}
                                className={
                                    "modal-service " +
                                    (this.state.addOns.filter(
                                        (
                                            item
                                        ) =>
                                            item._id ===
                                            service._id
                                    ).length >
                                        0 &&
                                        "modal-service-selected")
                                }
                                style={{ margin: "5px 5px", width: "100%", borderTop: "1px solid #94a3b8", }}
                            >
                                <h4
                                    style={{
                                        margin: "0",
                                    }}
                                >
                                    <strong style={{ paddingRight: "10px" }}>
                                        {service.name}
                                    </strong>
                                    <div
                                        style={{
                                            float: window.innerWidth > 480 && "right",

                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#8c8c8c",
                                            }}
                                        >
                                            {
                                                service.priceType === "From" && "From "

                                            }
                                            {" "}
                                            {currency}
                                            {
                                                service.price
                                            }
                                        </span>
                                    </div>
                                </h4>
                                {service.description >
                                    "" && (
                                        <span
                                            style={{
                                                color: "grey",
                                            }}
                                        >
                                            {
                                                service.description
                                            }
                                        </span>
                                    )}
                            </div>
                        );
                    }
                )}
                <div style={{ position: "sticky", bottom: "0", paddingTop: "5px", backgroundColor: "#ffffff" }}>
                    <Button
                        block size="large"
                        type="primary"
                        style={{ marginTop: "10px", borderRadius: "20px", }}
                        onClick={this.handleAddAddOnsToServices}
                        disabled={this.state.addOns.length === 0}

                    >
                        Add
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    menu: state.checkingIn.menu,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesAddOns)