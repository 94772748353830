import React, { Component } from "react";
import "./App.css";
// import "antd/dist/antd.css";
import './index.css';
import 'animate.css/animate.min.css';
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import CheckingIn from "./page/CheckingIn/CheckingIn";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import Home from "./page/Home/Home";
import { ConfigProvider } from 'antd';
import NotFoundPage from "./component/common/NotFoundPage/NotFoundPage";

ConfigProvider.config({
    theme: {
        primaryColor: '#00c2cb',
    },
});
const history = createBrowserHistory();

class App extends Component {
    render() {
        return (
            // <ConfigProvider prefixCls="custom">
            <div style={{ height: "100%" }}>
                <GoogleReCaptchaProvider reCaptchaKey="6LdIffQbAAAAAMgFxzkdudC171bEDOFNI2QUcfnp">
                    <Router>
                        <Switch>
                            <Route path="/:location/checking-in">
                                <CheckingIn history={history} {...this.props} />
                            </Route>

                            <Route path="/*" >
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </Router>
                </GoogleReCaptchaProvider>
            </div>
            // {/* </ConfigProvider> */}
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
